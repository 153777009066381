<template>
  <main class="page_bg_grey blank-aside-js min-h-100vh w-100" :class = "(isOpenAside=='true')?'blank-aside':''">
    <SuppliersPage
      banner_location="giftcards_home-top"
      supplier_router="GiftCardSupplier"
      product_router="GiftCardProduct"
      page_title="gift_cards"
    ></SuppliersPage>
  </main>
</template>

<script>
  import SuppliersPage from '@/components/endUser/suppliersPages/SuppliersPage.vue';

  export default {
    name: 'GiftCard',
    components: {
      SuppliersPage,
    },
  }
</script>